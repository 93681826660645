body {
  margin: 0;
  padding: 0;
  font-family: 'HelveticaNeue-Light', 'Helvetica Neue Light', 'Helvetica Neue',
    Helvetica, Arial, 'Lucida Grande', sans-serif;
  font-weight: 300;
  background-color: #ecf0f1;
  cursor: default;
}

* {
  -webkit-user-select: none;
          user-select: none;
}

html,
body,
#root,
#root > div {
  height: 100%;
}

body > div[id='root'] > div[data-reactroot] {
  height: 100%;
}

a {
  text-decoration: none !important;
}

input[type='file'] {
  opacity: 0;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
}

img {
  object-fit: cover;
}

img.emojione {
  width: 22px;
  height: 22px;
}

blockquote {
  background: #f9f9f9;
  border-left: 10px solid #ccc;
  margin: 1.5em 10px;
  padding: 0.5em 10px;
  quotes: '\201C''\201D''\2018''\2019';
}
blockquote:before {
  color: #ccc;
  content: open-quote;
  font-size: 4em;
  line-height: 0.1em;
  margin-right: 0.25em;
  vertical-align: -0.4em;
}
blockquote p {
  display: inline;
}

#ios-push-container {
  height: 117px;
  width: 325px;
  background-size: cover;
  margin-top: 290px;
  margin-left: 80px;
}

#iphone {
  display: block;
  height: 820px;
  width: 466px;
  background-image: url(http://pushnotificationpreview.com/Images/iPhone-6.png);
  background-size: cover;
  float: right;
}

#ios-push-title {
  font-family: Apple, sans-serif;
  color: black;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.5;
  width: 302px;
  height: 20px;
  margin-left: 36px;
  padding-top: 8px;
  opacity: 0.5;
  text-transform: uppercase;
}

#ios-push-body {
  font-family: Apple, sans-serif;
  color: black;
  font-weight: 500;
  font-size: 13.3px;
  line-height: 1.4;
  letter-spacing: 0.1px;
  width: 300px;
  margin-left: 14px;
  margin-top: -8px;
}

input,
textarea {
  -webkit-user-select: text !important;
  user-select: text !important;
}
